const appLocaleData = require('react-intl/locale-data/zh');


module.exports = {
    locale: 'zh-CN',
    data: appLocaleData,
    messages: {
        'app.home.introduce': '一款服务于投资账的辅助产品。『分析』和『认知』是基石，投资是一场修行，我们是经历者、同行者。愿我们这一路都有终点～',
        'app.home.design-language': '产品与服务',
        'app.home.solution': '解决方案',
        'app.home.components-explain': '基于 Ant Design 设计语言，我们提供了一套开箱即用的高质量 React 组件，用于开发和服务于企业级中后台产品，除官方的 React 实现，还有 Angular、Vue 的实现',
        'app.home.product-pro-slogan': '开箱即用的中台前端/设计解决方案',
        'app.home.product-mobile-slogan': 'antd-mobile 是 Ant Design 的移动规范的 React 实现',
        'app.home.product-antv-slogan': '简单、专业、拥有无限可能的数据可视化解决方案',
        'app.home.tool-package-title': '基金实时更新收益',
        'app.home.tool-package-content': '收益实时更新，无需等到第二天',
        'app.home.tool-library-title': '实时行业资金流动',
        'app.home.tool-library-content': '时刻注意大盘资金流向，分析大盘走向',
        'app.home.tool-kitchen-title': '基金涨幅榜',
        'app.home.tool-kitchen-content': '每日实时关注基金前五涨幅',
        'app.home.tool-note-title': '兜兜笔记',
        'app.home.tool-note-content': '记录投资心得认知，不断提升认知',
        'app.home.tool-stock-title': '支持股票资产',
        'app.home.tool-stock-content': '股票持仓每日更新',
        'app.home.tool-update-title': '敬请期待',
        'app.home.tool-update-content': '后续更新中...',
        'app.footer.repo': 'GitHub 仓库',
        'app.footer.awesome': 'Awesome Ant Design',
        'app.footer.chinamirror': '国内镜像站点 🇨🇳',
        'app.footer.primary-color-changed': '修改主题色成功！',
        'app.footer.scaffold': '脚手架',
        'app.footer.scaffolds': 'iphone',
        'app.footer.dev-tools': '开发工具',
        'app.footer.dva': 'Android',
        'app.footer.resources': '资源链接',
        'app.footer.eggjs': '企业级 Node 开发框架',
        'app.footer.motion': '设计动效',
        'app.footer.antd-library': 'Axure 部件库',
        'app.footer.design-platform': '蚂蚁金服设计平台',
        'app.footer.antux': '页面逻辑素材',
        'app.footer.community': '社区',
        'app.footer.change-log': '更新记录',
        'app.footer.faq': '常见问题',
        'app.footer.feedback': '反馈和建议',
        'app.footer.stackoverflow': 'StackOverflow',
        'app.footer.segmentfault': 'SegmentFault',
        'app.footer.discuss-en': '在线讨论 (English)',
        'app.footer.discuss-cn': '在线讨论 (中文)',
        'app.footer.bug-report': '报告 Bug',
        'app.footer.issues': '讨论列表',
        'app.footer.version': '文档版本：',
        'app.footer.work_with_us': '加入我们',
        'app.footer.more-product': '联系我们',
        'app.footer.more-watch': '豆粒日记',
        'app.footer.more-cooperation': '静候佳音',
        'app.footer.relief': '免责声明',
        'app.footer.retransmission': '转载声明',
        'app.footer.privacy': '隐私权政策',
        'app.footer.commitment': '豫ICP备2022024809号-1',
        'app.footer.company': '豆币信息科技（郑州）有限公司',
        'app.footer.ant-design': '豫公网安备 41019602002312号 ',
    },
};
