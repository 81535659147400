import React, {Component} from 'react';

import {BrowserRouter as Router, Route} from "react-router-dom";
import Home from './Home';
import Agreement from './Home/Agreement'
import Relief from './Home/Relief'
import article from './Home/article'
import Retransmission from './Home/Retransmission'
import './App.css';
import {enquireScreen} from "enquire-js";
import cnLocale from './zh-CN';
import {addLocaleData, IntlProvider} from 'react-intl';
import drafts from "./Home/drafts";


import Nav3 from "./Home/Nav3";
import Footer1 from "./Home/Footer1";
import Content11 from './Home/Content11';
import Content9 from './Home/Content9';

import {
    Nav30DataSource,
    Content110DataSource,
    Content90DataSource,
    Footer10DataSource,
} from './Home/data.source';

let isMobile;


enquireScreen((b) => {
    isMobile = b;
});


class App extends Component {
    state = {
        isMobile,
    }

    constructor(props) {
        super(props);
        const appLocale = cnLocale;
        addLocaleData(appLocale.data);
        this.state = {
            appLocale,
            isMobile,
        };
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b,
            });
        });
    }

    render() {
        const {appLocale} = this.state;
        return (
            <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
                <Router>
                    <div>
                        <Nav3
                            id="Nav3_0"
                            key="Nav3_0"
                            dataSource={Nav30DataSource}
                            isMobile={this.state.isMobile}
                        />
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/agreement" component={Agreement}/>
                        <Route exact path="/relief" component={Relief}/>
                        <Route exact path="/retransmission" component={Retransmission}/>
                        <Route exact path="/drafts" component={drafts}/>
                        <Route exact path="/article" component={article}/>
                        <Footer1
                            id="Footer1_0"
                            key="Footer1_0"
                            dataSource={Footer10DataSource}
                            isMobile={this.state.isMobile}
                        />
                    </div>
                </Router>
            </IntlProvider>
        );
    }
}

export default App;
