import React from 'react';

export const Nav30DataSource = {
    wrapper: {className: 'header3 home-page-wrapper'},
    page: {className: 'home-page'},
    logo: {
        className: 'header3-logo lhrgznahcsp-editor_css',
        children: 'logo1.png',
    },
    Menu: {
        className: 'header3-menu',
        children: [
            {
                name: 'item0',
                className: 'header3-item lhq49uhsbv-editor_css',
                children: {
                    href: '/',
                    children: [
                        {
                            children: (
                                <span>
                  <span>
                    <p>首页</p>
                  </span>
                </span>
                            ),
                            name: 'text',
                            className: 'lhq4b6mheg-editor_css',
                        },
                    ],
                    className: 'lhq4861yznc-editor_css',
                },
                subItem: null,
            },
            {
                name: 'item1',
                className: 'header3-item',
                children: {
                    href: '#',
                    children: [
                        {
                            children: (
                                // 文章
                                <span>
                  <span>
                    <p>

                    </p>
                  </span>
                </span>
                            ),
                            name: 'text',
                            className: 'lhq4b6mheg-editor_css',
                        },
                    ],
                },
            },
            {
                name: 'item2',
                className: 'header3-item',
                children: {
                    href: '#',
                    children: [
                        {
                            children: (
                                //帮助
                                <span>
                  <span>
                      <p></p>
                  </span>
                </span>
                            ),
                            name: 'text',
                            className: 'lhq4b6mheg-editor_css',
                        },
                    ],
                    className: 'lhq4861yznc-editor_css',
                },
            },
            {
                name: 'item3',
                className: 'header3-item',
                children: {
                    href: '#',
                    children: [
                        {
                            children: (
                                // 加入我们
                                <span>
                  <p></p>
                </span>
                            ),
                            name: 'text',
                            className: 'lhq4b6mheg-editor_css',
                        },
                    ],
                },
            },
        ],
    },
    mobileMenu: {className: 'header3-mobile-menu'},
};
export const Content110DataSource = {
    OverPack: {
        className: 'home-page-wrapper content11-wrapper lhq3pq7wmbd-editor_css',
        playScale: 0.3,
    },
    titleWrapper: {
        className: 'title-wrapper lhrgx73fq8s-editor_css',
        children: [
            {
                name: 'image',
                children:
                    'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
                className: 'title-image',
            },
            {name: 'title', children: '私人专属投资账', className: 'title-h1'},
            {
                name: 'content',
                children:
                    '建立个人投资体系，定期复盘投资策略，一手掌握个人资产情况',
                className: 'title-content',
            },
            {
                name: 'content2',
                children: '橘兜账本',
                className: 'title-content',
            },
        ],
    },
    button: {
        className: '',
        children: {
            a: {
                className: 'button lhq3rgdlcw-editor_css',
                href: 'https://apps.apple.com/cn/app/%E6%A9%98%E5%85%9C%E8%B4%A6%E6%9C%AC-%E4%B8%93%E5%B1%9E%E6%8A%95%E8%B5%84%E8%B4%A6%E8%AE%B0%E8%B4%A6%E7%AE%A1%E7%90%86app/id6443748232',
                children: '开始使用',
                target: '_blank',
            },
        },
    },
};
export const Content90DataSource = {
    wrapper: {className: 'home-page-wrapper content9-wrapper'},
    page: {className: 'home-page content9'},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'image',
                children:
                    'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
                className: 'title-image',
            },
            {
                name: 'title',
                children: (
                    <span>
            <span>
              <p>
                <span>一起学理财</span>
                <br/>
              </p>
            </span>
          </span>
                ),
                className: 'title-h1',
            },
        ],
    },
    block: {
        className: 'timeline lhrh9gdmf9m-editor_css',
        children: [
            {
                name: 'block0',
                className: 'block-wrapper',
                playScale: 0.3,
                children: {
                    imgWrapper: {className: 'image-wrapper'},
                    textWrapper: {className: 'text-wrapper'},
                    img: {
                        className: 'block-img lhrh84ztrnf-editor_css',
                        children: 'https://www.doubiai.com/favicon.ico',
                    },
                    icon: {
                        className: 'block-icon',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
                    },
                    name: {
                        className: 'block-name lhspieexpij-editor_css',
                        children: (
                            <span>
                <span>
                  <span>
                    <span>
                      <p>橘兜账本</p>
                    </span>
                  </span>
                </span>
              </span>
                        ),
                    },
                    post: {
                        className: 'block-post lhspiex2eah-editor_css',
                        children: (
                            <span>
                <span>
                  <p>
                    <br/>
                  </p>
                </span>
              </span>
                        ),
                    },
                    time: {
                        className: 'block-time',
                        children: (
                            <span>
                <span>
                  <p>自我介绍</p>
                </span>
              </span>
                        ),
                    },
                    title: {
                        className: 'block-title',
                        children: (
                            <span>
                <p>你好～🎉，欢迎来到橘兜账本。</p>
                <p>在这里我们与各位同学一起学习投资理财，一起面对未来人生。</p>
                <p>我们是经历者、同行者。愿我们这一路都有终点～</p>
              </span>
                        ),
                    },
                    content: {className: 'block-content', children: ''},
                },
            },
            {
                name: 'block1',
                className: 'block-wrapper',
                playScale: 0.3,
                children: {
                    imgWrapper: {className: 'image-wrapper'},
                    textWrapper: {className: 'text-wrapper'},
                    img: {
                        className: 'block-img lhrhnfdfzvl-editor_css',
                        children:
                            'https://doubiai-mood-1313089198.cos.ap-shanghai.myqcloud.com/sourcematerial/rc-upload-1684388311331-2|文章.png',
                    },
                    icon: {
                        className: 'block-icon',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
                    },
                    name: {
                        className: 'block-name lhspjltyo8-editor_css',
                        children: (
                            <span>
                <span>
                  <p>理财第一步</p>
                </span>
              </span>
                        ),
                    },
                    post: {
                        className: 'block-post',
                        children: (
                            <span>
                <p>
                  <br/>
                </p>
              </span>
                        ),
                    },
                    time: {
                        className: 'block-time',
                        children: (
                            <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>理财第一步</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
                        ),
                    },
                    title: {
                        className: 'block-title',
                        children: (
                            <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                与你一起搭建自己的理财体系，面对理财不焦虑，面对他人理财分享不盲目跟风
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
                        ),
                    },
                    content: {
                        className: 'block-content',
                        children: (
                            <span>
                <span>
                  <p>
                    <br/>
                  </p>
                </span>
              </span>
                        ),
                    },
                },
            },
            {
                name: 'block2',
                className: 'block-wrapper',
                playScale: 0.3,
                children: {
                    imgWrapper: {className: 'image-wrapper'},
                    textWrapper: {className: 'text-wrapper'},
                    img: {
                        className: 'block-img lhrhs6uh1nh-editor_css',
                        children:
                            'https://doubiai-mood-1313089198.cos.ap-shanghai.myqcloud.com/sourcematerial/rc-upload-1684388311331-4|笔记.png',
                    },
                    icon: {
                        className: 'block-icon',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
                    },
                    name: {
                        className: 'block-name lhspjvezy06-editor_css',
                        children: (
                            <span>
                <p>笔记广场</p>
              </span>
                        ),
                    },
                    post: {
                        className: 'block-post',
                        children: (
                            <span>
                <p>
                  <br/>
                </p>
              </span>
                        ),
                    },
                    time: {
                        className: 'block-time',
                        children: (
                            <span>
                <p>笔记广场</p>
              </span>
                        ),
                    },
                    title: {
                        className: 'block-title',
                        children: (
                            <span>
                <span>
                  <span>
                    <p>
                      将自己在投资过程中的心得记录下来，清晰明了复盘每一次投资<br/>
                    </p>
                  </span>
                </span>
              </span>
                        ),
                    },
                    content: {
                        className: 'block-content',
                        children: (

                            <span>
                            <p>
                              我的理财之路，记录投资心得体验，投资是一场长期修行，记录下所感所悟，所思所想
                            </p>
                          </span>

                        ),
                    },
                },
            },
            {
                name: 'block3',
                className: 'block-wrapper',
                playScale: 0.3,
                children: {
                    imgWrapper: {className: 'image-wrapper'},
                    textWrapper: {className: 'text-wrapper'},
                    img: {
                        className: 'block-img lhrhj5nakii-editor_css',
                        children:
                            'https://doubiai-mood-1313089198.cos.ap-shanghai.myqcloud.com/sourcematerial/rc-upload-1684388311331-6|资产.png',
                    },
                    icon: {
                        className: 'block-icon',
                        children:
                            'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
                    },
                    name: {
                        className: 'block-name lhspk39ry3c-editor_css',
                        children: (
                            <span>
                <p>资产管理</p>
              </span>
                        ),
                    },
                    post: {
                        className: 'block-post',
                        children: (
                            <span>
                <p>
                  <br/>
                </p>
              </span>
                        ),
                    },
                    time: {
                        className: 'block-time',
                        children: (
                            <span>
                <p>资产管理</p>
              </span>
                        ),
                    },
                    title: {
                        className: 'block-title',
                        children: (
                            <span>
                <span>
                  <p>收录散落各处的资产，一手掌握自身资产状况</p>
                </span>
              </span>
                        ),
                    },
                    content: {
                        className: 'block-content',
                        children: (
                            <span>
                <p>
                  合理规划管理资产，清晰记录资产变化，合理分配每一笔资产<br/>
                </p>
              </span>
                        ),
                    },
                },
            },
        ],
    },
};
export const Footer10DataSource = {
    wrapper: {className: 'home-page-wrapper footer1-wrapper'},
    OverPack: {className: 'footer1', playScale: 0.2},
    block: {
        className: 'home-page',
        gutter: 0,
        children: [
            {
                name: 'block0',
                xs: 24,
                md: 6,
                className: 'block',
                title: {
                    className: 'logo',
                    children: (
                        <span>
              <span>
                <p>橘兜</p>
              </span>
            </span>
                    ),
                },
                childWrapper: {
                    className: 'slogan',
                    children: [
                        {
                            name: 'content0',
                            children: (
                                <span>
                  <p>
                    一款服务于投资账的辅助产品。『分析』和『认知』是基石，投资是一场修行，我们是经历者、同行者。愿我们这一路都有终点～
                  </p>
                </span>
                            ),
                        },
                    ],
                },
            },
            {
                name: 'block1',
                xs: 24,
                md: 6,
                className: 'block',
                title: {
                    children: (
                        <span>
              <p>相关信息</p>
            </span>
                    ),
                },
                childWrapper: {
                    children: [
                        {
                            name: 'link1',
                            href: 'retransmission',
                            children: (
                                <span>
                  <p>转载注明</p>
                </span>
                            ),
                            target: '_blank',
                        },
                        {
                            name: 'link2',
                            href: 'relief',
                            children: (
                                <span>
                  <p>
                    免责声明<br/>
                  </p>
                </span>
                            ),
                            target: '_blank',
                        },
                        {
                            name: 'link3',
                            href: 'agreement',
                            children: (
                                <span>
                  <p>隐私协议</p>
                </span>
                            ),
                            target: '_blank',
                        }
                    ],
                },
            },
            {
                name: 'block2',
                xs: 24,
                md: 6,
                className: 'block',
                title: {children: '关于'},
                childWrapper: {
                    children: [
                        {
                            href: 'mailto:judou@doubiai.com',
                            name: 'link0',
                            children: (
                                <span>
                  <p>
                    商务合作：judou@doubiai.com<br/>
                  </p>
                </span>
                            ),
                            target: '_blank',
                        },
                        {
                            href: '#',
                            name: 'link1',
                            children: (
                                <span>
                  <p>
                    微信公众号 - 豆粒日记<br/>
                  </p>
                </span>
                            ),
                        },
                    ],
                },
            },
            {
                name: 'block3',
                xs: 24,
                md: 6,
                className: 'block',
                title: {children: '资源'},
                childWrapper: {
                    children: [
                        {
                            href:
                                'https://apps.apple.com/cn/app/%E6%A9%98%E5%85%9C%E8%B4%A6%E6%9C%AC-%E4%B8%93%E5%B1%9E%E6%8A%95%E8%B5%84%E8%B4%A6%E8%AE%B0%E8%B4%A6%E7%AE%A1%E7%90%86app/id6443748232',
                            name: 'link0',
                            children: (
                                <span>
                  <span>
                    <p>
                      橘兜iOS版 - iphone<br/>
                    </p>
                  </span>
                </span>
                            ),
                            target: '_blank',
                        },
                        {
                            href: '#',
                            name: 'link1',
                            children: (
                                <span>
                  <p>
                    橘兜安卓版 - Android<br/>
                  </p>
                </span>
                            ),
                        },
                    ],
                },
            },
        ],
    },
    copyrightWrapper: {className: 'copyright-wrapper'},
    copyrightPage: {className: 'home-page'},
    copyright: {
        className: 'copyright',
        children: (

            <span>
                 <a
                     href="https://beian.miit.gov.cn/"
                     target="_blank"
                 >
                豫ICP备2022024809号-1&nbsp;
            </a>
            <a
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41019602002312"
                target="_blank"
            >
                豫公网安备41019602002312号&nbsp;
            </a>
            <span>Copyright © 豆币信息科技（郑州）有限公司</span>
            </span>

        ),

    },
};
